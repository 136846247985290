<div class="card">
  <div class="card-header">Personal Projects</div>
  <div class="card-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1" class="nav-item" title="TimePic">
        <button ngbNavLink>Travel & Pictures</button>
        <ng-template ngbNavContent>
          <div class="nav-content">
            <app-photo-grid></app-photo-grid>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="nav-item" title="TimePic">
        <button ngbNavLink>TimePic</button>
        <ng-template ngbNavContent>
          <div class="nav-content">
            <img alt="TimePic App" src="/assets/timepic-icon.png" width="128px" />
            <br />
            <a href="/assets/privacy-policy.txt" class="btn btn-primary">Privacy Policy</a>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
