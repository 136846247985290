import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PhotoGridComponent } from './photo-grid/photo-grid.component';
import { LinkedinBadgeComponent } from './linkedin-badge/linkedin-badge.component';
import { HeaderComponent } from './header/header.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';
import {PersonalProjectsComponent} from './personal-projects/personal-projects.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PhotoGridComponent,
    LinkedinBadgeComponent,
    HeaderComponent,
    SocialNetworksComponent,
    PersonalProjectsComponent,
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
