import { Component } from '@angular/core';
import { NgxMasonryModule } from 'ngx-masonry';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'app-photo-grid',
  standalone: true,
  imports: [NgxMasonryModule, NgForOf],
  templateUrl: './photo-grid.component.html',
  styleUrl: './photo-grid.component.scss',
})
export class PhotoGridComponent {
  pictures = [];

  // fetch pictures from Google Picture API using key from user
  private fetchPictures() {

  }
}
