import { Component } from '@angular/core';
import {
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLinkBase,
  NgbNavLinkButton,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { PhotoGridComponent } from '../photo-grid/photo-grid.component';

@Component({
  selector: 'app-personal-projects',
  standalone: true,
  imports: [
    NgbNav,
    NgbNavContent,
    NgbNavLinkBase,
    NgbNavLinkButton,
    PhotoGridComponent,
    NgbNavItem,
    NgbNavOutlet,
  ],
  templateUrl: './personal-projects.component.html',
  styleUrl: './personal-projects.component.scss',
})
export class PersonalProjectsComponent {
  active = 1;

}
