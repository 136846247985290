import { Component } from '@angular/core';
import { LinkedinBadgeComponent } from '../linkedin-badge/linkedin-badge.component';

@Component({
  selector: 'app-social-networks',
  standalone: true,
  imports: [LinkedinBadgeComponent],
  templateUrl: './social-networks.component.html',
  styleUrl: './social-networks.component.scss',
})
export class SocialNetworksComponent {}
