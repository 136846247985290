import { Component } from '@angular/core';

import '../../lib/linkedin-badge.js';

@Component({
  selector: 'app-linkedin-badge',
  standalone: true,
  imports: [],
  templateUrl: './linkedin-badge.component.html',
  styleUrl: './linkedin-badge.component.scss',
})
export class LinkedinBadgeComponent {
  getTheme() {
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      return 'light';
    }
    return 'dark';
  }
}
